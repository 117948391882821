define("discourse/plugins/discourse-chat-integration/discourse/routes/transcript", ["exports", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/routes/discourse"], function (_exports, _service, _ajax, _ajaxError, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Transcript extends _discourse.default {
    static #_ = dt7948.g(this.prototype, "currentUser", [_service.inject]);
    #currentUser = (dt7948.i(this, "currentUser"), void 0);
    static #_2 = dt7948.g(this.prototype, "composer", [_service.inject]);
    #composer = (dt7948.i(this, "composer"), void 0);
    static #_3 = dt7948.g(this.prototype, "router", [_service.inject]);
    #router = (dt7948.i(this, "router"), void 0);
    async model(params) {
      if (!this.currentUser) {
        this.session.set("shouldRedirectToUrl", window.location.href);
        this.router.replaceWith("login");
        return;
      }
      await this.router.replaceWith("discovery.latest").followRedirects();
      try {
        const result = await (0, _ajax.ajax)(`/chat-transcript/${params.secret}`);
        this.composer.openNewTopic({
          body: result.content
        });
      } catch (e) {
        (0, _ajaxError.popupAjaxError)(e);
      }
    }
  }
  _exports.default = Transcript;
});